import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import './HubSpotForm.css'; // Import the CSS file

const StyledInput = styled('input')(({ theme }) => ({
  padding: '10px',
  fontSize: '16px',
  width: '100%',
  minWidth: '300px',
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  boxSizing: 'border-box',
  '&:hover': {
    borderColor: theme.palette.action.hover,
  },
  '&:focus': {
    outline: `2px solid ${theme.palette.primary.main}`,
    borderColor: theme.palette.primary.main,
  },
}));

const HubSpotForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const portalId = "47232808";
    const formId = "5988e162-df24-4091-ae84-5dd5d7a0199b";
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

    const data = {
      fields: [
        {
          name: "email",
          value: email
        }
      ]
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        setMessage('Form submitted successfully!');
      } else {
        setMessage('Failed to submit form.');
      }
    } catch (error) {
      setMessage('An error occurred.');
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit} className="styled-form">
        <label htmlFor="email"></label>
        <StyledInput
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="you@email.com"
          required
        />
        <button type="submit">Join the waitlist</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default HubSpotForm;

import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import HubSpotForm from './HubSpotForm';

const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  minHeight: 100, // Ensures the image has enough height on small screens
  marginTop: theme.spacing(2),
  backgroundImage: `url('/images/pebble_image.png')`,
  backgroundSize: 'cover', // Covers the entire container
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.up('sm')]: {
    minHeight: 150, // Fixed height for larger screens
  },
}));

const sentences = [
  "find the gaps in these product requirements?",
  "draft a tech spec?",
  "create tickets for this project?",
  "implement this ticket?"
];

export default function Hero() {
  const [currentSentence, setCurrentSentence] = React.useState('');
  const [sentenceIndex, setSentenceIndex] = React.useState(0);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [typingSpeed, setTypingSpeed] = React.useState(150);

  const handleTyping = React.useCallback(() => {
    const fullText = sentences[sentenceIndex];
    
    setCurrentSentence(prev => {
      if (isDeleting) {
        return prev.slice(0, -1);
      }
      if (prev === fullText) {
        setIsDeleting(true);
        return prev;
      }
      return fullText.slice(0, prev.length + 1);
    });

    setTypingSpeed(isDeleting ? 50 : 100);
  }, [sentenceIndex, isDeleting]);

  React.useEffect(() => {
    const typingInterval = setInterval(handleTyping, typingSpeed);
    return () => clearInterval(typingInterval);
  }, [handleTyping, typingSpeed]);

  React.useEffect(() => {
    if (isDeleting && currentSentence === '') {
      setIsDeleting(false);
      setSentenceIndex((prev) => (prev + 1) % sentences.length);
    }
  }, [currentSentence, isDeleting]);

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(33, 57%, 90%) 60%, transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%) 60%, transparent)',
        }),
      })}
    >
      <Container
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 10, sm: 10 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2} // Increased spacing between Stack items
          useFlexGap
          sx={{ width: { xs: '100%', sm: '90%' }, marginTop: 5 }}
        >
          <Box sx={{ display: 'flex', alignItems: 'baseline', width: '100%', minHeight: '200px' }}>
            <Typography
              variant='h1'
              sx={(theme) => ({
                textAlign: 'left',
                color: theme.palette.text.primary,
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                minWidth: 'fit-content', // Ensure consistent width
              })}
            >
              Hey pebble, can you...{currentSentence}
            </Typography>
          </Box>
          <StyledBox id="image" />
          <Typography
            variant='h3'
            sx={(theme) => ({
              textAlign: 'center',
              width: { sm: '100%', md: '80%' },
              color: theme.palette.text.primary, // Use primary text color
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              margin: '0 auto', // Center align the Typography
            })}
          >
            Pebble helps senior engineers scale their work
          </Typography>
          <Box sx={{ width: '100%', mt: 2, mb: -8 }}> {/* Added bottom margin */}
            <HubSpotForm />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}

import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import getMPTheme from './theme/getMPTheme';

export default function MarketingPage() {
  const MPTheme = createTheme(getMPTheme('light'));

  return (
    <ThemeProvider theme={MPTheme}>
      <CssBaseline />
      <AppAppBar />
      <Hero />
    </ThemeProvider>
  );
}
import * as React from 'react';
import Typography from '@mui/material/Typography';


export default function VCAIIcon() {
  return (
    <Typography 
      variant="h5" 
      sx={(theme) => ({ fontWeight: 700, color: theme.palette.text.primary })}
    >
      Pebble
    </Typography>
  );
}
